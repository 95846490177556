import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getFuelTypes, TranslatedFuelType } from '@api/endpoints/translations'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetFuels } from './queryKeys'

export const useQueryGetFuelTypes = (
  opts: {
    enabled?: boolean
  } = {}
): UseQueryResult<ReadonlyArray<TranslatedFuelType>> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()
  const query = useQuery({
    queryKey: queryKeyGetFuels,
    queryFn: getFuelTypes,
    ...opts
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
